<template>
  <div>
    <div :style="{ 'text-align': 'center', 'margin-top': '10px' }">
      <div :style="{ margin: '20px' }">
        <div
          :style="{
            'max-width': '750px',
            'text-align': 'left',
            'margin-left': 'auto',
            'margin-right': 'auto',
            display: 'flex',
          }"
        >
          <a-dropdown :style="{ 'min-width': '130px' }" class="link-outline">
            <a class="ant-dropdown-link" style="font-size: 14px">
              {{ $t("temp.test-type") }} <a-icon type="down" />
            </a>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="">
                <a>{{ $t("temp.all") }} </a>
              </a-menu-item>
              <a-menu-item key="Szondi">
                <a>{{ $t("temp.testszondi") }} </a>
              </a-menu-item>
              <a-menu-item key="Quadro">
                <a>{{ $t("temp.quadro-test") }} </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-range-picker style="margin-left: auto" @change="onChange" />
        </div>
      </div>
      <a-list
        class="results-loadmore-list"
        :loading="loading"
        item-layout="horizontal"
        :data-source="data"
      >
        <div
          v-if="showLoadingMore"
          slot="loadMore"
          :style="{
            textAlign: 'center',
            marginTop: '12px',
            height: '32px',
            lineHeight: '32px',
          }"
        >
          <a-spin v-if="loadingMore" />
          <a-button v-else @click="onLoadMore">
            {{ $t("buttons.loadMore") }}
          </a-button>
        </div>
        <a-list-item slot="renderItem" slot-scope="item">
          <a
            slot="actions"
            class="link-outline"
            @click="shareClicked($strapi.baseUrl + ('/results/' + item.udid))"
          >
            {{ $t("ui.share") }}
          </a>
          <a slot="actions" class="link-outline">
            <router-link :to="'/results/' + item.udid">
              {{ $t("ui.view") }}
            </router-link>
          </a>
          <a-list-item-meta :description="getTime(item.dateend)">
            <a
              slot="title"
              class="link-outline"
              :href="$strapi.baseUrl + '/results/' + item.udid"
              target="_blank"
              >{{ getTestName(item.testtype) }}
            </a>
            <a-avatar slot="avatar" :src="getTestIcon(item.testtype)" />
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

const moment = require("moment-timezone");
export default {
  middleware: "authenticated",
  data() {
    return {
      topVectors: ["S", "P", "Sch", "C"],

      loading: true,
      loadingMore: false,
      showLoadingMore: true,
      data: [],
      listQuery: {
        pagination: {
          pageSize: 10,
          page: 1,
        },
        filters: {
          testtype: {
            $eq: undefined,
          },
          datestart: {
            $gte: undefined,
          },
          dateend: {
            $lte: undefined,
          },
        },
        sort: {
          createdAt: "DESC",
        },
      },
    };
  },
  computed: {
    authId() {
      return this.id;
    },
    testResultsList() {
      return this.getTestResultsList;
    },
    ...mapGetters(["getTestResultsList", "id"]),
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.$loading.start();
    // });

    this.listQuery.pagination.page = 1;
    this.getData((res) => {
      this.loading = false;
      this.data = res;
    });

    // this.$nextTick(() => {
    //   setTimeout(() => this.$loading.finish(), 500);
    // });
  },
  methods: {
    getTime(time) {
      return moment(time).format("DD.MM.YYYY, HH:mm");
    },
    isDate(value) {
      switch (typeof value) {
        case "number":
          return true;
        case "string":
          return !isNaN(Date.parse(value));
        case "object":
          if (value instanceof Date) {
            return !isNaN(value.getTime());
          }
          break;
        default:
          return false;
      }
    },
    onChange(date, dateString) {
      if (this.isDate(dateString[0]) && this.isDate(dateString[1])) {
        this.listQuery.filters.datestart = {
          $gte: date[0].toDate(),
        };
        this.listQuery.filters.dateend = {
          $lte: date[1].toDate(),
        };
      } else {
        this.listQuery.filters.datestart = {
          $gte: undefined,
        };
        this.listQuery.filters.dateend = {
          $lte: undefined,
        };
      }

      this.listQuery.pagination.page = 1;
      this.getData((res) => {
        this.loading = false;
        this.data = res;
      });
    },
    handleMenuClick(e) {
      if (e.key === "") {
        this.listQuery.filters.testtype = {
          $eq: undefined,
        };
      } else {
        this.listQuery.filters.testtype = {
          $eq: e.key,
        };
      }
      this.listQuery.pagination.page = 1;
      this.getData((res) => {
        this.loading = false;
        this.data = res;
      });
    },
    getTestName(testType) {
      switch (testType) {
        case "Quadro":
          return this.$t("temp.quadro-test");
        case "Szondi":
          return this.$t("temp.testszondi");
        case "default":
          return "";
      }
    },
    getTestIcon(testType) {
      const list = this.$store.state.tests.list;
      for (let i = 0; i < list.length; i++) {
        if (testType === list[i].type) {
          return this.$strapi.storageUrl + list[i].image.url;
        }
      }
      return "";
    },
    async shareClicked(url) {
      try {
        await this.$copyText(url);
        this.$notification["success"]({
          message: this.$t("ui.linkCopied"),
          description: false,
        });
      } catch (e) {
        this.$notification["error"]({
          message: this.$t("ui.ErrorOccurred"),
          description: false,
        });
      }
    },
    getData(callback) {
      this.$store
        .dispatch("testResults/fetchTestResultsList", this.listQuery)
        .then((results) => {
          // console.log(results)
          callback(results);
        });
    },
    onLoadMore() {
      const nextPagesExist =
        this.data.length == this.listQuery.pagination.pageSize;

      if (nextPagesExist) {
        this.listQuery.pagination.page += 1;

        this.loadingMore = true;
        this.getData((res) => {
          if (this.data !== res) {
            this.data = this.data.concat(res);
            this.loadingMore = false;
            this.$nextTick(() => {
              window.dispatchEvent(new Event("resize"));
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.results-loadmore-list {
  max-width: 600px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.demo-loadmore-list {
  min-height: 350px;
}

#ant-list > .ant-list-item-extra-wrap {
  width: 100%;
}
</style>
