var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{style:({ 'text-align': 'center', 'margin-top': '10px' })},[_c('div',{style:({ margin: '20px' })},[_c('div',{style:({
          'max-width': '750px',
          'text-align': 'left',
          'margin-left': 'auto',
          'margin-right': 'auto',
          display: 'flex',
        })},[_c('a-dropdown',{staticClass:"link-outline",style:({ 'min-width': '130px' })},[_c('a',{staticClass:"ant-dropdown-link",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t("temp.test-type"))+" "),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.handleMenuClick},slot:"overlay"},[_c('a-menu-item',{key:""},[_c('a',[_vm._v(_vm._s(_vm.$t("temp.all"))+" ")])]),_c('a-menu-item',{key:"Szondi"},[_c('a',[_vm._v(_vm._s(_vm.$t("temp.testszondi"))+" ")])]),_c('a-menu-item',{key:"Quadro"},[_c('a',[_vm._v(_vm._s(_vm.$t("temp.quadro-test"))+" ")])])],1)],1),_c('a-range-picker',{staticStyle:{"margin-left":"auto"},on:{"change":_vm.onChange}})],1)]),_c('a-list',{staticClass:"results-loadmore-list",attrs:{"loading":_vm.loading,"item-layout":"horizontal","data-source":_vm.data},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{},[_c('a',{staticClass:"link-outline",attrs:{"slot":"actions"},on:{"click":function($event){_vm.shareClicked(_vm.$strapi.baseUrl + ('/results/' + item.udid))}},slot:"actions"},[_vm._v(" "+_vm._s(_vm.$t("ui.share"))+" ")]),_c('a',{staticClass:"link-outline",attrs:{"slot":"actions"},slot:"actions"},[_c('router-link',{attrs:{"to":'/results/' + item.udid}},[_vm._v(" "+_vm._s(_vm.$t("ui.view"))+" ")])],1),_c('a-list-item-meta',{attrs:{"description":_vm.getTime(item.dateend)}},[_c('a',{staticClass:"link-outline",attrs:{"slot":"title","href":_vm.$strapi.baseUrl + '/results/' + item.udid,"target":"_blank"},slot:"title"},[_vm._v(_vm._s(_vm.getTestName(item.testtype))+" ")]),_c('a-avatar',{attrs:{"slot":"avatar","src":_vm.getTestIcon(item.testtype)},slot:"avatar"})],1)],1)}}])},[(_vm.showLoadingMore)?_c('div',{style:({
          textAlign: 'center',
          marginTop: '12px',
          height: '32px',
          lineHeight: '32px',
        }),attrs:{"slot":"loadMore"},slot:"loadMore"},[(_vm.loadingMore)?_c('a-spin'):_c('a-button',{on:{"click":_vm.onLoadMore}},[_vm._v(" "+_vm._s(_vm.$t("buttons.loadMore"))+" ")])],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }